'use client';

import { Cross2Icon } from '@radix-ui/react-icons';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { DataTableViewOptions } from '@/components/common/table/data-table-view-options';

import { CrmContactUploader } from '../crm-contact-uploader/CrmContactUploader';
import ContactFilterSheet from '../CrmContactFilterSheet';

export function CrmContactTableToolbar({ table }) {
  return (
    <div className='space-y-2'>
      <div className='flex items-center justify-between'>
        <ContactFilterSheet />
        <div className='flex items-center space-x-2'>
          <CrmContactUploader />
          <DataTableViewOptions table={table} />
        </div>
      </div>

      {table.getFilteredSelectedRowModel().rows.length > 0 && (
        <div className='flex items-center justify-between'>
          <div className='flex flex-1 flex-wrap items-center  space-y-2 md:space-x-2 md:space-y-0'>
            {table.getFilteredSelectedRowModel().rows.map((row) => (
              <Badge key={row.id} variant={'grey'}>
                @{row.getValue('id')}-{row.getValue('name')}
              </Badge>
            ))}

            <Button
              variant='ghost'
              onClick={() => table.resetRowSelection()}
              className='h-8 px-2 lg:px-3'
            >
              Clear Selection
              <Cross2Icon className='ml-2 h-4 w-4' />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
