import useContactStore from '@/stores/ContactStore';

import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import CustomerReaction from './CustomerReaction';

export default function StoryReplyMessage({
  direction,
  media_url,
  is_seen,
  emoji,
  customer_reaction,
  text,
  contact_id,
  channel,
  _id: message_id,
}) {
  const username = useContactStore((state) => state.ig_username);
  const media_type =
    media_url.split('.').reverse()[0] === 'mp4' ? 'video' : 'img';

  return (
    <div className='group relative'>
      <StoryReplyCaption direction={direction} />
      <div
        className={cn(
          'group relative rounded-lg px-1 pt-1 text-sm',
          media_url ? 'pb-3' : 'pb-1',
          direction === 'sent'
            ? 'bg-muted'
            : 'bg-received text-primary-foreground'
        )}
      >
        {media_type === 'video' ? (
          <video
            controls
            preload='metadata'
            className='max-h-[350px] min-h-[350px] max-w-[350px] rounded-md'
          >
            <source src={media_url} type='video/mp4' />
          </video>
        ) : (
          <Dialog>
            <DialogTrigger>
              <img
                src={media_url}
                className='max-h-[250px] max-w-[250px] rounded-md'
                alt='image message'
              />
            </DialogTrigger>
            <DialogContent
              className='flex h-screen max-w-full items-center justify-center'
              onCloseAutoFocus={(e) => e.preventDefault()}
            >
              <div className='max-w-screen max-h-screen'>
                <img
                  src={media_url}
                  className='max-h-[95vh] max-w-full object-contain'
                  alt='image message'
                />
              </div>
            </DialogContent>
          </Dialog>
        )}
        <blockquote
          className={cn(
            'm-1 max-w-[200px]  italic',
            media_url ? 'border-l-2 pl-2' : ''
          )}
        >
          {text}
        </blockquote>
      </div>
      <StoryEmoji
        emoji={emoji}
        direction={direction}
        is_seen={is_seen}
        customer_reaction={customer_reaction}
      />
      {direction !== 'sent' && channel === 'instagram' && (
        <CustomerReaction
          direction={direction}
          customer_reaction={customer_reaction}
          message_id={message_id}
          contact_id={contact_id}
        />
      )}
    </div>
  );
}

const StoryReplyCaption = () => (
  <div className='flex flex-col text-muted-foreground'>
    <span>replied to your story</span>
  </div>
);

const StoryEmoji = ({ emoji, customer_reaction, direction, is_seen }) =>
  (emoji || customer_reaction) && (
    <span
      className={cn(
        'absolute -bottom-3 rounded-full border bg-primary-foreground px-[3px] text-xs text-primary',
        direction !== 'sent' ? 'left-2' : is_seen ? 'right-8' : 'right-5'
      )}
    >
      {emoji}
      {customer_reaction ? (emoji === '❤' ? null : '❤') : null}
      {emoji && customer_reaction ? '2' : null}
    </span>
  );
