import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import CustomerReaction from './CustomerReaction';

export default function ImageMessage({
  media_url,
  direction,
  is_seen,
  channel,
  contact_id,
  _id: message_id,
  emoji,
  customer_reaction,
}) {
  return (
    <div className='group relative'>
      <Dialog>
        <DialogTrigger>
          <img
            src={media_url}
            className='max-h-[250px] max-w-[250px] rounded-md'
            alt='image message'
          />
          {is_seen && (
            <span className={'absolute -bottom-2 right-0'}>
              <CheckCheckIcon className='h-4 w-4 text-seen' />
            </span>
          )}
          {(emoji || customer_reaction) && (
            <span
              className={cn(
                'absolute -bottom-3 rounded-full border bg-primary-foreground px-[3px] text-xs text-primary',
                direction !== 'sent'
                  ? 'left-2'
                  : is_seen
                    ? 'right-5'
                    : 'right-2'
              )}
            >
              {emoji}
              {customer_reaction ? (emoji === '❤' ? null : '❤') : null}
              {emoji && customer_reaction ? '2' : null}
            </span>
          )}
        </DialogTrigger>
        <DialogContent
          className='flex h-screen max-w-full items-center justify-center'
          onCloseAutoFocus={(e) => e.preventDefault()}
        >
          <div className='max-w-screen max-h-screen'>
            <img
              src={media_url}
              className='max-h-[95vh] max-w-full object-contain'
              alt='image message'
            />
          </div>
        </DialogContent>
      </Dialog>
      {direction !== 'sent' && channel === 'instagram' && (
        <CustomerReaction
          direction={direction}
          customer_reaction={customer_reaction}
          message_id={message_id}
          contact_id={contact_id}
        />
      )}
    </div>
  );
}
