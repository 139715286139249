import { useRef, useState } from 'react';
import {
  AudioLinesIcon,
  Loader2Icon,
  PaperclipIcon,
  SendHorizonalIcon,
  X,
} from 'lucide-react';
import { toast } from 'sonner';
import { z } from 'zod';

import { Button } from '@/components/ui/button';

export default function FileAttachment({
  setIsAttachmentSelected,
  messageType,
  setMessageType,
  addMessage,
}) {
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const imageFileSchema = z.object({
    size: z
      .number()
      .max(8 * 1024 * 1024, { message: 'Image file size cannot exceed 8MB' }),
    type: z.enum(['image/png', 'image/jpeg', 'image/jpg', 'image/gif'], {
      errorMap: () => ({
        message:
          'Invalid image file type. Please select a valid image file from png/jpeg/jpg/gif',
      }),
    }),
  });

  const audioFileSchema = z.object({
    size: z
      .number()
      .max(25 * 1024 * 1024, { message: 'Audio file size cannot exceed 25MB' }),
    type: z.enum(
      [
        'audio/aac',
        'audio/m4a',
        'audio/wav',
        'audio/mp4',
        'audio/x-m4a',
        'audio/vnd.dlna.adts',
      ],
      {
        errorMap: () => ({
          message:
            'Invalid audio file type. Please select a valid audio file from aac/m4a/wav/mp4/vnd.dlna.adts ',
        }),
      }
    ),
  });

  const videoFileSchema = z.object({
    size: z
      .number()
      .max(25 * 1024 * 1024, { message: 'Video file size cannot exceed 25MB' }),
    type: z.enum(
      ['video/mp4', 'video/ogg', 'video/avi', 'video/mov', 'video/webm'],
      {
        errorMap: () => ({
          message:
            'Invalid video file type. Please select a valid video file from mp4/ogg/avi/mov/webm',
        }),
      }
    ),
  });

  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0] || null;
    console.log('type --<> ', selectedFile.type);
    if (selectedFile) {
      let schema;
      if (selectedFile.type.startsWith('image/')) {
        schema = imageFileSchema;
        setMessageType('image');
      } else if (selectedFile.type.startsWith('audio/')) {
        schema = audioFileSchema;
        setMessageType('audio');
      } else if (selectedFile.type.startsWith('video/')) {
        schema = videoFileSchema;
        setMessageType('video');
      } else {
        toast.error(
          'Invalid file type. Please select an image, audio, or video file.'
        );
        event.target.value = '';
        handleRemoveFile();
        return;
      }

      if (schema) {
        const result = schema.safeParse(selectedFile);
        if (!result.success) {
          toast.error(result.error.errors[0].message, {
            description: 'Please select a file within the size limit',
          });

          // Reset the input value to ensure onChange is triggered for the same file
          event.target.value = '';
          handleRemoveFile();
          return;
        }
      }

      setFile(selectedFile);
      setIsAttachmentSelected(true);
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setIsAttachmentSelected(false);
    setMessageType('text');
  };

  const handleFileMessage = async (e) => {
    e.preventDefault();
    if (['audio', 'video', 'image'].includes(messageType) && file !== null) {
      setIsLoading(true);
      await addMessage(file, messageType);
      setIsLoading(false);
      handleRemoveFile();
    }
  };

  return (
    <div className='flex flex-grow items-end justify-between gap-2'>
      <div className='flex flex-row items-end gap-2'>
        <input
          type='file'
          accept='.png, .jpg, .jpeg, .gif, .aac, .m4a, .wav, .mp4, .ogg, .avi, .mov, .webm'
          ref={fileInputRef}
          onChange={handleFileChange}
          className='hidden'
        />
        <Button
          size='xs'
          variant='ghost'
          disabled={isLoading}
          onClick={handleFileUpload}
        >
          <PaperclipIcon className='h-5 w-5' />
        </Button>
        {file && (
          <FileCard
            isLoading={isLoading}
            file={file}
            onRemove={handleRemoveFile}
          />
        )}
      </div>
      {messageType !== 'text' && (
        <Button
          size='xs'
          variant='ghost'
          disabled={isLoading}
          className='text-primary hover:text-primary/90'
          onClick={(e) => handleFileMessage(e)}
        >
          {isLoading ? (
            <Loader2Icon className='animate-spin' />
          ) : (
            <SendHorizonalIcon />
          )}
        </Button>
      )}
    </div>
  );
}

export function FileCard({ file, onRemove, isLoading }) {
  const isImage = file.type.startsWith('image/');
  const isVideo = file.type.startsWith('video/');
  const isAudio = file.type.startsWith('audio/');

  return (
    <div className='flex max-w-[400px] flex-row  items-center gap-2 rounded-md bg-gray-800 p-1'>
      {isImage && (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className='max-h-8 min-h-8 max-w-10 rounded-md object-cover'
        />
      )}
      {isVideo && (
        <video
          src={URL.createObjectURL(file)}
          className='max-h-10 min-h-8 max-w-8 rounded-md object-cover'
          controls
        />
      )}
      {isAudio && (
        <AudioLinesIcon className='max-h-10 max-w-10  rounded object-cover' />
      )}
      <div className='flex max-w-[200px] items-center gap-2'>
        <div className='flex-1 truncate'>
          <p className='truncate text-sm text-gray-200'>{file.name}</p>
          <p className='text-xs text-gray-400'>
            {file.size < 1024 * 1024
              ? // Convert bytes to MB or KB based on size
                `${(file.size / 1024).toFixed(2)} KB`
              : `${(file.size / (1024 * 1024)).toFixed(2)} MB`}
          </p>
        </div>
        <Button
          variant='ghost'
          size='icon'
          onClick={onRemove}
          disabled={isLoading}
          className='h-6 w-6 text-gray-400 hover:text-gray-200'
        >
          <X className='h-4 w-4' />
        </Button>
      </div>
    </div>
  );
}
