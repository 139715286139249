import { InfoCircledIcon } from '@radix-ui/react-icons';
import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

import CustomerReaction from './CustomerReaction';

export default function CustomMessage({
  direction,
  is_seen,
  emoji,
  customer_reaction,
  contact_id,
  channel,
  _id: message_id,
  customMessage,
}) {
  return (
    <div
      className={cn(
        'group relative rounded-lg  px-3 py-2 text-left text-sm',
        direction === 'sent'
          ? 'bg-muted'
          : 'bg-received text-primary-foreground'
      )}
    >
      <p className='flex items-center justify-center space-x-2'>
        <InfoCircledIcon />
        <span>{customMessage}</span>
      </p>
      {is_seen && (
        <span className='absolute -bottom-2 right-0'>
          <CheckCheckIcon className='h-4 w-4 text-seen' />
        </span>
      )}
      {(emoji || customer_reaction) && (
        <span
          className={cn(
            'absolute -bottom-3 rounded-full border bg-primary-foreground px-[3px] text-xs text-primary',
            direction !== 'sent' ? 'left-2' : is_seen ? 'right-5' : 'right-2'
          )}
        >
          {emoji}
          {customer_reaction ? (emoji === '❤' ? null : '❤') : null}
          {emoji && customer_reaction ? '2' : null}
        </span>
      )}
      {direction !== 'sent' && channel === 'instagram' && (
        <CustomerReaction
          direction={direction}
          customer_reaction={customer_reaction}
          message_id={message_id}
          contact_id={contact_id}
        />
      )}
    </div>
  );
}
