import {
  crmContactSearch,
  followerUpload,
  followerUploadHistory,
} from '@/constants/endpoints';

import { APIClient } from '@/lib/ApiClient';

const apiClient = new APIClient();

export function getCRMContactList(pageNumber, filter) {
  return apiClient.create(crmContactSearch(pageNumber), filter, true);
}

export function getFollowerUploadHistory(pageNumber) {
  return apiClient.get(followerUploadHistory(pageNumber), null, true);
}

export function postFollowerUpload(uploadId, formData) {
  return apiClient.create(
    followerUpload(uploadId),
    formData,
    true,
    false,
    'multipart/form-data'
  );
}
