import useContactStore from '@/stores/ContactStore';
import {
  CheckCheckIcon,
  ExternalLinkIcon,
  MessageSquareMoreIcon,
  MessageSquareQuoteIcon,
  ReplyIcon,
} from 'lucide-react';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { retrieveCommentThread } from '@/hooks/contact';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

export default function CommentMessage({
  direction,
  text,
  media_url,
  media_permalink,
  media_type,
  is_privately_responded,
  timestamp,
  channel,
  _id: messageId,
  sbc_msg_parent_id,
}) {
  const {
    setCommentThread,
    setCommentThreadAccordianState,
    setSelectedPrivateReplyMessage,
  } = useContactStore(
    useShallow((state) => ({
      setCommentThreadAccordianState: state.setCommentThreadAccordianState,
      setCommentThread: state.setCommentThread,
      setSelectedPrivateReplyMessage: state.setSelectedPrivateReplyMessage,
    }))
  );

  const openExternalLink = (url) => {
    window.open(url, '_blank');
  };

  const handleCommentThread = async (messageId) => {
    try {
      const response = await retrieveCommentThread(messageId);
      const {
        _id,
        contacts_data,
        contact_id,
        text,
        timestamp,
        child_comments,
        ig_comment_id,
      } = response.data;

      const transformedData = [
        {
          id: _id,
          username: contacts_data[contact_id].ig_username,
          content: text,
          timestamp: timestamp,
          profile_picture_url: contacts_data[contact_id].profile_picture_url,
          contact_id: contact_id,
          ig_comment_id: ig_comment_id,
          children: child_comments.map(
            ({ _id, contact_id, text, timestamp }) => ({
              id: _id,
              username: contacts_data[contact_id].ig_username,
              contact_id: contact_id,
              content: text,
              timestamp: timestamp,
              profile_picture_url:
                contacts_data[contact_id].profile_picture_url,
            })
          ),
        },
      ];

      setCommentThread(transformedData);
      setCommentThreadAccordianState('chat-history');
    } catch (error) {
      console.error('error fetching comment thread:', error);
      toast.error('Failed to retrieve comment thread');
    }
  };

  const handlePrivateReply = () => {
    if (is_privately_responded) {
      toast.error('Comments can only be privately replied once');
    } else {
      setSelectedPrivateReplyMessage(messageId, text);
    }
  };

  const isRecent = (timestamp) => {
    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    const messageDate = new Date(timestamp).getTime();

    return messageDate > oneWeekAgo;
  };

  return (
    <>
      <div
        className={cn(
          'group relative rounded-lg px-1 pt-1 text-sm',
          media_url ? 'pb-3' : 'pb-1',
          direction === 'sent'
            ? 'bg-muted'
            : 'bg-received text-primary-foreground'
        )}
      >
        {media_url !== undefined && (
          <div
            className={cn(
              'absolute -bottom-4 -right-5 z-50 hidden flex-row gap-2 rounded-2xl px-3 py-1 shadow-md group-hover:flex',
              direction === 'sent'
                ? 'bg-primary-foreground'
                : 'bg-muted text-primary'
            )}
          >
            <>
              {isRecent(timestamp) && channel === 'instagram' && (
                <ReplyIcon
                  className={cn('h-5 w-5 cursor-pointer')}
                  onClick={() => handlePrivateReply()}
                />
              )}
              {sbc_msg_parent_id !== undefined ? (
                sbc_msg_parent_id !== null ? (
                  //If comment is not parent comment, then trigger the API with parent message id
                  <MessageSquareMoreIcon
                    className='h-5 w-5 cursor-pointer'
                    onClick={() => handleCommentThread(sbc_msg_parent_id)}
                  />
                ) : (
                  //If comment is parent comment, then trigger the API with the self messageID
                  <MessageSquareMoreIcon
                    className='h-5 w-5 cursor-pointer'
                    onClick={() => handleCommentThread(messageId)}
                  />
                )
              ) : null}

              {media_permalink !== undefined ? (
                <ExternalLinkIcon
                  className='h-5 w-5 cursor-pointer'
                  onClick={() => openExternalLink(media_permalink)}
                />
              ) : null}
            </>
          </div>
        )}
        <span className='absolute -top-2 right-0 bg-received text-sm text-primary-foreground'>
          <MessageSquareQuoteIcon className='h-5 w-5' />
        </span>
        {media_url ? (
          media_type === 'image' ||
          media_type === 'photo' ||
          media_type === 'album' ||
          media_type === 'carousel_album' ? (
            <Dialog>
              <DialogTrigger>
                <img
                  src={media_url}
                  className='max-h-[250px] max-w-[250px] rounded-md'
                  alt='image message'
                />
              </DialogTrigger>
              <DialogContent
                className='flex h-screen max-w-full items-center justify-center'
                onCloseAutoFocus={(e) => e.preventDefault()}
              >
                <div className='max-w-screen max-h-screen'>
                  <img
                    src={media_url}
                    className='max-h-[95vh] max-w-full object-contain'
                    alt='image message'
                  />
                </div>
              </DialogContent>
            </Dialog>
          ) : media_type === 'video' ? (
            <video
              controls
              preload='metadata'
              className='max-h-[350px] min-h-[350px] max-w-[350px] rounded-md'
            >
              <source src={media_url} type='video/mp4' />
            </video>
          ) : null
        ) : null}
        <blockquote
          className={cn(
            'm-1 max-w-[200px]  italic',
            media_url ? 'border-l-2 pl-2' : ''
          )}
        >
          {text}
        </blockquote>
      </div>
    </>
  );
}
