import { useState } from 'react';
import useContactStore from '@/stores/ContactStore';
import { intervalToDuration, parseISO } from 'date-fns';
import { SendHorizonalIcon, XIcon } from 'lucide-react';
import { toast } from 'sonner';
import { useShallow } from 'zustand/react/shallow';

import { sendReplyToComment } from '@/hooks/contact';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';

const CommentThread = () => {
  const [sendCommentLoading, setSendCommentLoading] = useState(false);

  const {
    commentThreadList,
    setCommentThread,
    resetCommentThread,
    commentThreadAccordianState,
    setCommentThreadAccordianState,
    commentMessage,
    setCommentMessage,
  } = useContactStore(
    useShallow((state) => ({
      commentThreadList: state.commentThread,
      setCommentThread: state.setCommentThread,
      resetCommentThread: state.resetCommentThread,
      commentThreadAccordianState: state.commentThreadAccordianState,
      setCommentThreadAccordianState: state.setCommentThreadAccordianState,
      commentMessage: state.commentMessage,
      setCommentMessage: state.setCommentMessage,
    }))
  );

  const handleAccordionToggle = () => {
    if (commentThreadAccordianState === 'chat-history') {
      resetCommentThread();
    } else {
      setCommentThreadAccordianState('chat-history');
    }
  };

  const handleSendComment = () => {
    if (!commentMessage || !commentThreadList.length) return;

    const {
      id: sbcParentId,
      ig_comment_id: commentId,
      contact_id: contactId,
    } = commentThreadList[0];

    const updateCommentThread = (ig_comment_id, ig_username, contact_id) => {
      setCommentThread([
        {
          ...commentThreadList[0],
          children: [
            ...commentThreadList[0].children,
            {
              id: ig_comment_id,
              username: ig_username,
              content: commentMessage,
              contact_id: contact_id,
              timestamp: new Date().toISOString(),
              profile_picture_url: null,
            },
          ],
        },
      ]);
    };

    setSendCommentLoading(true);
    setCommentMessage('');
    toast.promise(
      sendReplyToComment(sbcParentId, commentId, commentMessage, contactId),
      {
        loading: 'Sending reply...',
        success: (response) => {
          const { ig_comment_id, ig_username, contact_id } = response;
          updateCommentThread(ig_comment_id, ig_username, contact_id);
          setSendCommentLoading(false);
          return 'Reply sent successfully';
        },
        error: () => {
          setSendCommentLoading(false);
          return 'Failed to send reply';
        },
      }
    );
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      // If Shift key is also pressed, insert a newline character
      if (e.shiftKey) {
        // dont do anything
      } else {
        // Otherwise, prevent the default behavior (submitting the form) and perform your button click action
        e.preventDefault();
        handleSendComment();
      }
    }
  };

  return (
    <div className='border-b border-borderColor px-4'>
      <Accordion
        type='single'
        collapsible
        className='w-full'
        onValueChange={() => handleAccordionToggle()}
        value={commentThreadAccordianState ? 'chat-history' : ''}
      >
        <AccordionItem value='chat-history'>
          <AccordionTrigger
            className='p-1 hover:no-underline'
            closeIcon={XIcon}
          >
            <div className='flex w-full justify-between text-sm'>
              Comment History
            </div>
          </AccordionTrigger>
          <AccordionContent className='pb-2'>
            <div className='custom-scrollbar my-2 max-h-80 w-full overflow-y-auto'>
              {commentThreadList.length ? (
                <div>
                  <div>
                    {commentThreadList.map((comment) => (
                      <Comment key={comment.id} comment={comment} />
                    ))}
                  </div>

                  <div className='mb-1 mt-4 flex w-full items-center justify-center space-x-1'>
                    <Textarea
                      type='text'
                      placeholder='Type your reply comment here...'
                      className='custom-scrollbar ml-3 h-8 min-h-14 w-full resize-none'
                      value={commentMessage}
                      onChange={(e) => setCommentMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                      disabled={sendCommentLoading}
                    />
                    <Button
                      size='xs'
                      variant='ghost'
                      className='text-primary hover:text-primary/90'
                      onClick={() => handleSendComment()}
                      disabled={sendCommentLoading}
                    >
                      <SendHorizonalIcon />
                    </Button>
                  </div>
                </div>
              ) : (
                <div className='cursor-default text-gray-500'>
                  Select a comment to view its history
                </div>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CommentThread;

function Comment({ comment }) {
  const defaultPic = `https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg`;
  const getTimeDiff = (timestamp) => {
    const date = parseISO(timestamp);
    const duration = intervalToDuration({ start: date, end: new Date() });

    const {
      years = 0,
      months = 0,
      days = 0,
      hours = 0,
      minutes = 0,
      seconds = 0,
    } = duration;

    const totalHours = years * 8760 + months * 730 + days * 24 + hours;
    const totalMinutes = totalHours * 60 + minutes;
    const totalSeconds = totalMinutes * 60 + seconds;

    let timeString = '';
    if (totalHours >= 48) {
      if (duration.years > 0) {
        timeString = `${duration.years}y`;
      } else if (duration.months > 0) {
        timeString = `${duration.months}mth`;
      } else {
        timeString = `${duration.days}d`;
      }
    } else if (totalHours >= 1) {
      timeString = `${totalHours}h`;
    } else if (totalMinutes >= 1) {
      timeString = `${totalMinutes}m`;
    } else {
      timeString = `${totalSeconds}s`;
    }

    return timeString;
  };

  return (
    <div className='space-y-2'>
      {/* Parent comment */}
      <div className='mb-2 flex gap-2'>
        <Avatar className='h-6 w-6'>
          {comment.profile_picture_url ? (
            <AvatarImage src={comment.profile_picture_url} />
          ) : (
            <AvatarImage src={defaultPic} />
          )}
          <AvatarFallback className='bg-muted'>
            <AvatarImage src={defaultPic} />
            {comment.username.charAt(0)}
          </AvatarFallback>
        </Avatar>
        <div>
          <div className='flex items-center gap-2'>
            <a
              href={`/inbox/${comment.contact_id}`}
              target='_blank'
              rel='noopener noreferrer'
              className='font-medium text-blue-500'
            >
              {comment.username}
            </a>
            <span className='text-sm text-muted-foreground'>
              {getTimeDiff(comment.timestamp)}
            </span>
          </div>
          <p className='mt-1 text-sm'>{comment.content}</p>
        </div>
      </div>

      {/* Child comments */}
      {comment.children && comment.children.length > 0 && (
        <div className='ml-4 mt-2 pl-4'>
          {comment.children.map((reply) => (
            <div key={reply.id} className='relative mt-2'>
              <div className='flex gap-2'>
                <Avatar className='h-6 w-6'>
                  {reply.profile_picture_url ? (
                    <AvatarImage src={reply.profile_picture_url} />
                  ) : (
                    <AvatarImage src={defaultPic} />
                  )}
                  <AvatarFallback className='bg-muted'>
                    <AvatarImage src={defaultPic} />
                    {reply.username.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                <div>
                  <div className='flex items-center gap-2'>
                    <a
                      className='font-medium text-blue-500'
                      href={`/inbox/${reply.contact_id}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {reply.username}
                    </a>
                    <span className='text-sm text-muted-foreground'>
                      {getTimeDiff(reply.timestamp)}
                    </span>
                  </div>
                  <p className='mt-1 text-sm'>{reply.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
