import avatarFB from '@/assets/images/avatar-fb.png';
import avatarIG from '@/assets/images/avatar-ig.png';
import useContactStore from '@/stores/ContactStore';
import { Link } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { trackIGLinkClicked } from '@/lib/analytics-event';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';

import CommentThread from './CommentThread';
import ContactOwner from './ContactOwner';
import ContactTags from './ContactTag';
import TipTapEditor from './Notes';

const ContactDetails = () => {
  const {
    fullName,
    profilePictureUrl,
    igUsername,
    plan,
    contactId,
    channel,
    ai_notes,
  } = useContactStore(
    useShallow((state) => ({
      fullName: state.fullname,
      profilePictureUrl: state.profile_picture_url,
      igUsername: state.ig_username,
      ai_notes: state.ai_notes,
      plan: state.plan,
      contactId: state._id,
      channel: state.channel,
    }))
  );

  if (!contactId) return null;

  return (
    <div className='flex w-full flex-col space-y-4 overflow-hidden'>
      <div className='flex flex-row items-center justify-start space-x-2 border-b border-borderColor px-4 py-3'>
        <div>
          <Avatar className='h-20 w-20 self-center'>
            <AvatarImage
              src={
                profilePictureUrl
                  ? profilePictureUrl
                  : 'https://files.sbccrm.com/sbccrm/sbccrm/contacts/pics/no-pic.jpg'
              }
            />
            <AvatarFallback>{fullName.charAt(0)}</AvatarFallback>
          </Avatar>
        </div>

        <div className='flex w-64 flex-col items-start py-2'>
          <h5 className='max-w-full overflow-hidden text-ellipsis whitespace-nowrap px-2 text-left text-sm'>
            {fullName}
          </h5>
          {channel === 'facebook' ? (
            <Button variant='link' size='sm' className='cursor-default'>
              <img src={avatarFB} className='mr-2 aspect-square h-4 w-4' />
              {/* <FacebookIcon className='mr-2 h-4 w-4 text-foreground' /> */}
            </Button>
          ) : channel === 'instagram' ? (
            <Link
              to={`https://www.instagram.com/${igUsername}`}
              target='_blank'
              className='-mt-1.5'
              onClick={() => trackIGLinkClicked()}
            >
              <Button variant='link' size='sm' className='px-2'>
                <img src={avatarIG} className='mr-2 aspect-square h-4 w-4' />
                {/* <InstagramIcon className='mr-2 h-4 w-4 text-foreground' /> */}
                {igUsername}
              </Button>
            </Link>
          ) : null}
          {plan ? (
            <div className='px-2'>
              <Button className='h-6 rounded-md px-2'>
                {plan?.name} - ${plan?.amount}
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <ContactTags />

      <ContactOwner />

      <CommentThread />

      <div className='space-y-2 border-b border-borderColor px-4'>
        <div className='text-sm font-medium'>Notes</div>
        <div className='space-y-2'>
          <div>
            <AINotes notes={ai_notes} />
          </div>
          <div>
            <TipTapEditor />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;

const AINotes = ({ notes }) => {
  return (
    notes && (
      <div className='relative rounded-full p-2'>
        <p className='relative z-10 whitespace-pre-line text-[0.85rem] leading-[1.1rem]'>
          {notes}
        </p>
        <div className='mask-gradient absolute inset-0 rounded-md bg-gradient-to-r from-purple-600 to-yellow-500 p-[2px]' />
      </div>
    )
  );
};
