import useContactStore from '@/stores/ContactStore';
import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';

import CustomerReaction from './CustomerReaction';

export default function StoryMessage({
  direction,
  media_url,
  is_seen,
  emoji,
  contact_id,
  channel,
  _id: message_id,
  customer_reaction,
}) {
  const username = useContactStore((state) => state.ig_username);
  const media_type =
    media_url.split('.').reverse()[0] === 'mp4' ? 'video' : 'img';

  return (
    <div className='group relative'>
      <StoryCaption direction={direction} username={username} />
      <div
        className={cn(
          'h-100 flex',
          direction === 'sent' ? 'flex-row-reverse' : 'flex-row'
        )}
      >
        <div className='mx-1 rounded border-2 border-secondary' />
        {media_type === 'video' ? (
          <video
            controls
            preload='metadata'
            className='max-h-[350px] max-w-[350px] rounded-md'
          >
            <source src={media_url} type='video/mp4' />
          </video>
        ) : (
          <Dialog>
            <DialogTrigger>
              <img
                src={media_url}
                className='max-h-[250px] max-w-[250px] rounded-md'
                alt='image message'
              />
            </DialogTrigger>
            <DialogContent
              className='flex h-screen max-w-full items-center justify-center'
              onCloseAutoFocus={(e) => e.preventDefault()}
            >
              <div className='max-w-screen max-h-screen'>
                <img
                  src={media_url}
                  className='max-h-[95vh] max-w-full object-contain'
                  alt='image message'
                />
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <StoryIsSeen is_seen={is_seen} />
      <StoryEmoji
        emoji={emoji}
        direction={direction}
        is_seen={is_seen}
        customer_reaction={customer_reaction}
      />
      {direction !== 'sent' && channel === 'instagram' && (
        <CustomerReaction
          direction={direction}
          customer_reaction={customer_reaction}
          message_id={message_id}
          contact_id={contact_id}
        />
      )}
    </div>
  );
}

const StoryCaption = ({ direction, username }) => (
  <div className='flex flex-col text-muted-foreground'>
    {direction === 'sent' ? (
      <span>{`You mentioned @${username} in your story`}</span>
    ) : (
      <span>Mentioned you in their story</span>
    )}
  </div>
);

const StoryIsSeen = ({ is_seen }) =>
  is_seen && (
    <span className={'absolute -bottom-2 right-3'}>
      <CheckCheckIcon className='h-4 w-4 text-seen' />
    </span>
  );

const StoryEmoji = ({ emoji, customer_reaction, direction, is_seen }) =>
  (emoji || customer_reaction) && (
    <span
      className={cn(
        'absolute -bottom-3 rounded-full border bg-primary-foreground px-[3px] text-xs text-primary',
        direction !== 'sent' ? 'left-4' : is_seen ? 'right-8' : 'right-5'
      )}
    >
      {emoji}
      {customer_reaction ? (emoji === '❤' ? null : '❤') : null}
      {emoji && customer_reaction ? '2' : null}
    </span>
  );
