import useContactStore from '@/stores/ContactStore';
import mixpanel from 'mixpanel-browser';

let Mixpanel = {
  register: () => {},
  get_distinct_id: () => {},
  identify: () => {},
  track: () => {},
  track_custom_pageview: () => {},
  people: {
    set: () => {},
    set_once: () => {},
  },
  reset: () => {},
  get_current_contact: () => {},
};

// if (import.meta.env.MODE === 'production') {
// mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {
//   debug: true,
//   track_pageview: true,
//   persistence: 'localStorage',
// });

// Mixpanel = {
//   get_distinct_id: () => {
//     return mixpanel.get_distinct_id();
//   },
//   identify: (id) => {
//     mixpanel.identify(id);
//   },
//   register: (data) => {
//     mixpanel.register(data);
//   },
//   track: (event, data) => {
//     mixpanel.track(event, data);
//   },
//   track_custom_pageview: (data) => {
//     mixpanel.track_pageview(data);
//   },
//   people: {
//     set: (data) => {
//       mixpanel.people.set(data);
//     },
//     set_once: (data) => {
//       mixpanel.people.set_once(data);
//     },
//   },
//   reset: () => {
//     mixpanel.reset();
//   },
//   get_current_contact: () => ({
//     contact_id: useContactStore.getState()._id,
//     contact_fullname: useContactStore.getState().fullname,
//     contact_ig_username: useContactStore.getState().ig_username,
//   }),
// };
// // }

export default Mixpanel;
