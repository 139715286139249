import { useState } from 'react';
import { fbAuth } from '@/constants/endpoints';
import useUserStore from '@/stores/UserStore';
import { Loader2Icon } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { APIClient } from '@/lib/ApiClient';

import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { Button } from '../ui/button';
import { Separator } from '../ui/separator';

const apiClient = new APIClient();

const Instagram = () => {
  return (
    <div className='h-full space-y-6'>
      <div className='flex justify-between'>
        <div>
          <h3 className='text-lg font-medium'>Instagram and Facebook</h3>
          <p className='text-sm text-muted-foreground'>
            Manage your connection
          </p>
        </div>
      </div>
      <Separator />
      <InstagramConnection />
    </div>
  );
};

export default Instagram;

const InstagramConnection = () => {
  const [loading, setLoading] = useState(false);

  let { user } = useUserStore(
    useShallow((state) => ({
      user: state.user,
    }))
  );

  const fetchRedirectURI = async () => {
    try {
      const response = await apiClient.get(fbAuth, null, true);

      return response.redirect_url;
    } catch (error) {
      // Handle API request errors
      console.error('API Error:', error);
    }
  };

  const callFacebook = async () => {
    setLoading(true);
    // Make a call to fetch the url -> add the url to a var -> open popup, after popup close -> make another call to fetch and store customer information
    try {
      const redirectURI = await fetchRedirectURI();

      // console.log('Redirect url --> ', redirectURI);

      window.location.href = redirectURI;

      // let params =
      //   'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000';
      // const popup = window.open(redirectURI, 'Facebook Login', params);
      // const timer = setInterval(function () {
      //   if (popup.closed) {
      //     clearInterval(timer);
      //     dispatch(fetchUser());
      //   }
      // }, 1000);
    } catch (error) {
      // Handle API request errors
      console.error('API Error:', error);
    }
  };

  const defaultPic = `http://graph.facebook.com/${user?.customer?.fb_id}/picture?type=large`;
  // user = null;

  return (
    <div className='flex items-center justify-center pt-48'>
      {user?.customer?.ig_name ? (
        <>
          <div className='flex items-center gap-4'>
            <Avatar className='hidden h-9 w-9 sm:flex'>
              <AvatarImage
                src={
                  user?.customer?.profile_pic_url
                    ? user?.customer?.profile_pic_url
                    : defaultPic
                }
                alt='Avatar'
              />
              <AvatarFallback>
                {user?.customer?.ig_name.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <div className='grid gap-1'>
              <p className='text-sm font-medium leading-none'>
                {user?.customer?.ig_name}
              </p>
              <p className='text-sm text-muted-foreground'>
                @{user?.customer?.ig_username}
              </p>
            </div>
          </div>
          {/* <div className='flex items-center'>
            <div className='mr-2'>
              <img
                src={
                  //   userDetails.customer?.profile_pic_url
                  //     ? userDetails.customer?.profile_pic_url
                  //     :
                  defaultPic
                }
                className='h-16 w-16 rounded-full'
                alt='profile'
              />
            </div>
            <div className='flex flex-col'>
              <span className='text-lg font-bold'>
                {userDetails.customer?.name} 
              </span>
              <span className='text-lg font-bold'>
                ( @{userDetails.customer?.ig_username} ) 
              </span>
            </div>
          </div> */}
          {/* <div className='mt-5'>
                <Button
                    color='danger'
                    onClick={toggle}
                    className='flex justify-center items-center'
                >
                    <i class='ri-close-line text-xl'></i> Disconnect
                </Button>
            </div> */}
        </>
      ) : (
        <div className='flex flex-col items-center'>
          <span>You have not connected an IG Account</span>
          <div className='mt-5'>
            {loading ? (
              <Button size='xs' variant='ghost'>
                <Loader2Icon className='animate-spin' />
              </Button>
            ) : (
              <Button
                onClick={callFacebook}
                className='flex items-center justify-center'
              >
                Connect
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
