import { CheckCheckIcon, MessageSquareQuoteIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

export default function PrivateReplyCommentMessage({
  direction,
  media_url,
  is_seen,
  emoji,
  customer_reaction,
  private_reply_comment,
  private_reply_parent_data,
  text,
  contact_id,
  _id: message_id,
}) {
  return (
    <div className='group relative'>
      <div
        className={cn(
          'group relative rounded-lg px-1 pt-1 text-sm',
          media_url ? 'pb-3' : 'pb-1',
          direction === 'sent'
            ? 'bg-muted'
            : 'bg-received text-primary-foreground'
        )}
      >
        {private_reply_comment && private_reply_parent_data && (
          <>
            <div className='m-1 rounded-md bg-received p-1 italic text-primary-foreground'>
              {private_reply_parent_data.text}
            </div>
            <span className='absolute -top-1 right-2 bg-received text-sm text-primary-foreground'>
              <MessageSquareQuoteIcon className='h-5 w-5' />
            </span>
          </>
        )}
        <blockquote
          className={cn(
            'm-1 max-w-[200px] border-l-2 border-primary pl-2 italic'
          )}
        >
          {text}
        </blockquote>
      </div>
      <MessageIsSeen is_seen={is_seen} />
      <MessageEmoji
        emoji={emoji}
        direction={direction}
        is_seen={is_seen}
        customer_reaction={customer_reaction}
      />
    </div>
  );
}

const MessageEmoji = ({ emoji, customer_reaction, direction, is_seen }) =>
  (emoji || customer_reaction) && (
    <span
      className={cn(
        'absolute -bottom-3 rounded-full border bg-primary-foreground px-[3px] text-xs text-primary',
        direction !== 'sent' ? 'left-2' : is_seen ? 'right-8' : 'right-5'
      )}
    >
      {emoji}
      {customer_reaction ? (emoji === '❤' ? null : '❤') : null}
      {emoji && customer_reaction ? '2' : null}
    </span>
  );

const MessageIsSeen = ({ is_seen }) =>
  is_seen && (
    <span className={'absolute -bottom-2 right-3'}>
      <CheckCheckIcon className='h-4 w-4 text-seen' />
    </span>
  );
