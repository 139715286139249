import { CheckCheckIcon } from 'lucide-react';

import { cn } from '@/lib/utils';

import CustomerReaction from './CustomerReaction';

export default function VideoMessage({
  direction,
  media_url,
  is_seen,
  emoji,
  channel,
  contact_id,
  _id: message_id,
  customer_reaction,
}) {
  return (
    <div className='group relative max-h-[350px] min-h-[350px]'>
      <video
        controls
        preload='metadata'
        className='max-h-[350px]  max-w-[350px] rounded-md'
      >
        <source src={media_url} type='video/mp4' />
      </video>
      {is_seen && (
        <span className={'absolute -bottom-2 right-0'}>
          <CheckCheckIcon className='h-4 w-4 text-seen' />
        </span>
      )}
      {(emoji || customer_reaction) && (
        <span
          className={cn(
            'absolute -bottom-3 rounded-full border bg-primary-foreground px-[3px] text-xs text-primary',
            direction !== 'sent' ? 'left-3' : is_seen ? 'right-5' : 'right-2'
          )}
        >
          {emoji}
          {customer_reaction ? (emoji === '❤' ? null : '❤') : null}
          {emoji && customer_reaction ? '2' : null}
        </span>
      )}
      {direction !== 'sent' && channel === 'instagram' && (
        <CustomerReaction
          direction={direction}
          customer_reaction={customer_reaction}
          message_id={message_id}
          contact_id={contact_id}
        />
      )}
    </div>
  );
}
