import useContactStore from '@/stores/ContactStore';
import { HeartFilledIcon, HeartIcon } from '@radix-ui/react-icons';
import { toast } from 'sonner';

import { cn } from '@/lib/utils';
import { sendReactionToMessage } from '@/hooks/contact';

export default function CustomerReaction({
  direction,
  customer_reaction,
  message_id,
  contact_id,
}) {
  const setMessageCustomerReaction = useContactStore(
    (state) => state.setMessageCustomerReaction
  );

  const handleCustomerReaction = async (emoji) => {
    toast.promise(
      sendReactionToMessage(message_id, contact_id, 'instagram', emoji),
      {
        loading: 'Reacting to message...',
        success: (response) => {
          console.log('response for customer reaction > ', response);
          const { message } = response;
          setMessageCustomerReaction(message_id, emoji);
          return message;
        },
        error: () => {
          return 'Unable to react to message';
        },
      }
    );
  };

  return (
    <div
      className={cn(
        'absolute -bottom-4 -right-3 z-50 hidden flex-row gap-2 rounded-2xl px-1 py-1 shadow-md group-hover:flex',
        direction === 'sent' ? 'bg-primary-foreground' : 'bg-muted text-primary'
      )}
    >
      <>
        {customer_reaction === '❤' ? (
          <HeartFilledIcon
            className='  h-5 w-5 cursor-pointer text-red-500'
            onClick={() => handleCustomerReaction(null)}
          />
        ) : (
          <HeartIcon
            onClick={() => handleCustomerReaction('love')}
            className='h-5 w-5 cursor-pointer text-primary'
          />
        )}
      </>
    </div>
  );
}
