// src/Tiptap.jsx
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useContactStore from '@/stores/ContactStore';
import { useHotkeys } from '@blueprintjs/core';
import Placeholder from '@tiptap/extension-placeholder';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useParams } from 'react-router-dom';

import {
  trackContactNoteClicked,
  trackContactNoteUpdated,
} from '@/lib/analytics-event';
import { createContactNote, fetchContactNotes } from '@/hooks/contact';
import { Button } from '@/components/ui/button';
import TooltipComponent from '@/components/common/tooltip/tooltip';

const TipTapEditor = ({ editable = true }) => {
  const { id: contactId } = useParams();

  const [initialContent, setInitialContent] = useState('');
  const contactNote = useContactStore((state) => state.note);
  const setContactNote = useContactStore((state) => state.setContactNote);

  const prevContentRef = useRef(initialContent);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Write a note…',
      }),
    ],
    content: initialContent,
    editable: editable,
    editorProps: {
      attributes: {
        class:
          'min-h-[200px] border-2 rounded-md p-2 rounded-[inherit] ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-ring',
      },
      onKeyDown: (view, event) => {
        console.log('esc pressed');
        if (event.key === 'Escape') {
          event.preventDefault();
          view.dom.blur();
          return true;
        }
        return false;
      },
    },
  });

  const handleEditorBlur = useCallback(async () => {
    // Get the current editor content
    const content = editor.getHTML();

    if (content !== prevContentRef.current) {
      const response = await createContactNote(contactId, content);
      setContactNote(response.note);
      prevContentRef.current = content;
      trackContactNoteUpdated(content.length);
    }
  }, [editor, contactId, setContactNote]);

  // Use useEffect to update the editor content when the initialContent prop changes
  useEffect(() => {
    if (editor) {
      editor.commands.setContent(initialContent);
      prevContentRef.current = initialContent;
    }
  }, [editor, initialContent]);

  useEffect(() => {
    setInitialContent(contactNote);
  }, [contactNote]);

  const handleFocus = useCallback(() => {
    editor?.commands?.focus();
    trackContactNoteClicked('keyboard');
  }, [editor]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'N',
        global: true,
        group: 'Contact Details',
        label: 'Edit Notes',
        onKeyDown: handleFocus,
      },
    ],
    [handleFocus]
  );

  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys);

  return (
    <>
      {editor && (
        <BubbleMenu tippyOptions={{ duration: 100 }} editor={editor}>
          <Button
            variant='outline'
            size='xs'
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
          >
            Bold
          </Button>
          <Button
            variant='outline'
            size='xs'
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
          >
            Italic
          </Button>
          <Button
            variant='outline'
            size='xs'
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive('strike') ? 'is-active' : ''}
          >
            Strike
          </Button>
        </BubbleMenu>
      )}
      {/* <TooltipComponent
        content={
          <div className='flex space-x-2'>
            <div>Edit note</div>

            <kbd className='pointer-events-none  hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex'>
              N
            </kbd>
          </div>
        }
      > */}
      <EditorContent
        className='pb-5 text-sm'
        editor={editor}
        onClick={() => trackContactNoteClicked('mouse')}
        onBlur={handleEditorBlur}
        onKeyUp={handleKeyUp}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            if (editor) {
              editor.commands.blur();
              e.preventDefault();
            }
          } else {
            handleKeyDown(e);
          }
        }}
      />
      {/* </TooltipComponent> */}
    </>
  );
};

export default TipTapEditor;
