const API_BASE_URL = import.meta.env.VITE_API_URL;

// auth endpoints
export const login = `${API_BASE_URL}/api/login`;
export const register = `${API_BASE_URL}/api/register`;
export const fbAuth = `${API_BASE_URL}/api/auth/facebook`;

// conversation/contact endpoints
export const conversationList = `${API_BASE_URL}/api/conversations`;
export const contactDetails = (contactId) =>
  `${API_BASE_URL}/api/contacts/${contactId}`;
export const contactMessages = (contactId) =>
  `${API_BASE_URL}/api/messages/${contactId}`;
export const sendMessage = `${API_BASE_URL}/api/instagram/send-message`;
export const sendMedia = (contactId) =>
  `${API_BASE_URL}/api/instagram/send-media?contact_id=${contactId}`;
export const addContactTag = `${API_BASE_URL}/api/contact/add-tag`;
export const contactTagList = (contactId) =>
  `${API_BASE_URL}/api/contact/list-tags?contact_id=${contactId}`;
export const removeContactTag = `${API_BASE_URL}/api/contact/remove-tag`;
export const setContactStatus = `${API_BASE_URL}/api/contact/status`;
export const assignAgent = `${API_BASE_URL}/api/contact/assign-agent`;
export const addContactNote = `${API_BASE_URL}/api/contact/note`;
export const contactNote = (contactId) =>
  `${API_BASE_URL}/api/contact/note?contact_id=${contactId}`;
export const attachPlan = `${API_BASE_URL}/api/contact/attach-plan`;

// report endpoints
export const agentActivityReport = `${API_BASE_URL}/api/report/messages`;
export const offerPresentedReport = `${API_BASE_URL}/api/report/offer-presented`;
export const closedWonReport = `${API_BASE_URL}/api/report/closed-won`;

// coach-view endpoints
export const coachViewMessages = `${API_BASE_URL}/api/admin/coach-view`;
export const coachContactViewMessages = `${API_BASE_URL}/api/admin/coach-contact-view`;

// system endpoints
export const userDetails = `${API_BASE_URL}/api/user/detail`;
export const agentCreate = `${API_BASE_URL}/api/agents/create`;
export const agentList = `${API_BASE_URL}/api/agents/list`;
export const customerTagList = `${API_BASE_URL}/api/list-tags`;
export const planCreate = `${API_BASE_URL}/api/plan/create`;
export const planList = `${API_BASE_URL}/api/plan/list`;
export const planUpdate = `${API_BASE_URL}/api/plan/update`;
export const planDelete = (planId) =>
  `${API_BASE_URL}/api/plan/delete/${planId}`;

// crm endpoints - https://qa.sbccrm.com/bld/api/contact/search
export const crmContactSearch = (pageNumber) =>
  `${API_BASE_URL}/api/contact/search?page=${pageNumber}`;

// follower upload
const SCRAPPER_URL = 'https://scrapper.sbccrm.com';
export const followerUpload = (uploadId) =>
  `${SCRAPPER_URL}/upload?upload-id=${uploadId}`;
export const followerUploadHistory = (pageNumber) =>
  `${SCRAPPER_URL}/uploads?page=${pageNumber}&per_page=5`;

// comment urls
export const commentThread = (messageId) =>
  `${API_BASE_URL}/api/message/${messageId}`;
export const replyToComment = `${API_BASE_URL}/api/instagram/reply-to-comment`;

// reaction url
export const reactMessage = (channel) =>
  `${API_BASE_URL}/api/${channel}/message/react`;
