import { useEffect, useState } from 'react';
import {
  CheckCircledIcon,
  ClockIcon,
  CrossCircledIcon,
} from '@radix-ui/react-icons';
import { CalendarClockIcon } from 'lucide-react';
import { toast } from 'sonner';

import {
  getFollowerUploadHistory,
  postFollowerUpload,
} from '@/hooks/crm-contact';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import { ScrollArea } from '@/components/ui/scroll-area';
import { FileUploader } from '@/components/common/fileupload/fileupload';

const getStatusIcon = (status) => {
  if (status === 'done') {
    return <CheckCircledIcon className='text-green-500' />;
  } else if (status === 'wip') {
    return <ClockIcon className='h-5 w-5 text-yellow-500' />;
  } else if (status === 'yts') {
    return <CalendarClockIcon className='h-5 w-5 text-white' />;
  } else {
    return <CrossCircledIcon className='text-red-500' />;
  }
};

export function CrmContactUploader() {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [filesSent, setFilesSent] = useState(0);
  const [uploadHistory, setUploadHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const uploadFiles = async () => {
    setIsUploading(true);
    setFilesSent(0);
    const uploadId = `${Math.floor(Date.now() / 1000)}`;
    try {
      for (const [index, file] of files.entries()) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await postFollowerUpload(uploadId, formData);
        setFilesSent(index + 1);
      }

      toast.success(`Files uploaded`);
      setFiles([]);
      fetchUploadHistory(); // Re-fetch upload history after upload
    } catch (error) {
      toast.error(`Failed to upload files`);
    } finally {
      setIsUploading(false);
    }
  };

  const fetchUploadHistory = async () => {
    setIsLoading(true);
    try {
      const response = await getFollowerUploadHistory(currentPage);
      console.log('res', response);
      setUploadHistory(response.batches);
      setTotalPages(response.meta.total_pages);
      setHasNext(response.meta.has_next);
      setHasPrev(response.meta.has_prev);
    } catch (error) {
      console.error('Failed to fetch upload history', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUploadHistory();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='outline' size='sm'>
          Upload Follower
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-xl'>
        <DialogHeader>
          <DialogTitle>Upload Follower</DialogTitle>
          <DialogDescription>
            Drag and drop your files here or click to browse. You can upload up
            to 10 files at a time, each with a maximum size of 8MB.
          </DialogDescription>
        </DialogHeader>

        <FileUploader
          value={files}
          maxFileCount={10}
          maxSize={8 * 1024 * 1024}
          onValueChange={setFiles}
        />

        <Button
          onClick={uploadFiles}
          disabled={files.length === 0 || isUploading}
        >
          {isUploading ? `Sending ${filesSent}/${files.length}` : 'Send'}
        </Button>
        <hr className='my-4' />

        <UploadHistory
          uploadHistory={uploadHistory}
          currentPage={currentPage}
          totalPages={totalPages}
          isLoading={isLoading}
          onPageChange={handlePageChange}
          hasNext={hasNext}
          hasPrev={hasPrev}
        />
      </DialogContent>
    </Dialog>
  );
}

const UploadHistory = ({
  uploadHistory,
  currentPage,
  totalPages,
  isLoading,
  onPageChange,
  hasNext,
  hasPrev,
}) => {
  return (
    <div>
      <h2 className='mb-4 text-lg font-semibold '>Upload history</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <ScrollArea className='h-fit w-full px-3'>
          <div className='max-h-70 flex flex-col gap-4'>
            <Accordion type='single' collapsible className='w-full'>
              {uploadHistory.length === 0 ? (
                <div>No upload history available.</div>
              ) : (
                uploadHistory.map((row) => {
                  return (
                    <AccordionItem
                      value={row.upload_id}
                      key={row.upload_id}
                      className='border px-2'
                    >
                      <AccordionTrigger className='hover:no-underline'>
                        <div className='flex w-full justify-between'>
                          <div className='text-base'>{row.upload_id}</div>
                          <div className='mr-2 flex items-center space-x-4'>
                            <div className=''>{getStatusIcon(row.status)}</div>
                            <div className=' text-sm font-light'>
                              {row.percentage.success}%
                            </div>
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <ol className='my-3 ml-6 list-disc space-y-4 [&>li]:mt-2'>
                          {row.jobs.map((job, index) => {
                            return (
                              <li key={job.id} className='list-decimal'>
                                <div className='flex justify-between pr-2'>
                                  <div className='mr-2 '>File {index + 1}</div>
                                  <div className='flex'>
                                    <div className='mr-2'>
                                      {getStatusIcon(job.status)}
                                    </div>
                                    <div>{job.percentage.success}%</div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ol>
                      </AccordionContent>
                    </AccordionItem>
                  );
                })
              )}
            </Accordion>
          </div>
        </ScrollArea>
      )}
      <Pagination className='mt-4'>
        <PaginationContent>
          {hasPrev && (
            <PaginationPrevious
              onClick={() =>
                onPageChange(currentPage > 1 ? currentPage - 1 : 1)
              }
            />
          )}
          {Array.from({ length: totalPages }, (_, index) => (
            <PaginationItem key={index}>
              <PaginationLink
                isActive={index + 1 === currentPage}
                onClick={() => onPageChange(index + 1)}
              >
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          {hasNext && (
            <PaginationNext
              onClick={() =>
                onPageChange(
                  currentPage < totalPages ? currentPage + 1 : totalPages
                )
              }
            />
          )}
        </PaginationContent>
      </Pagination>
    </div>
  );
};
